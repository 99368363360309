<template>
  <div>
    <b-link class="brand-logo">
      <div class="d-flex mt-2 mb-1 mx-1">
        <add-co-logo />
        <h2 class="brand-text text-primary font-weight-bolder mb-0">
          AddCo
        </h2>
      </div>
    </b-link>

    <!-- Loading -->
    <loading
      v-if="isLoading || isSubmitting"
      :active="true" 
      :is-full-page="true"
      color="#498ceb"
    />

    <div v-if="!isLoading" class="misc-inner m-2">
      <b-card v-if="clientNotFound">
        <h2 class="text-danger">
          {{ $t('client_not_found_check_url') }}
        </h2>
      </b-card>
    
      <div v-else>
        <div>
          <b-card>
            <h5 class="mb-2">
              {{ isSubmitted ? $t('message.complaint_sent') : $t('message.complaint_confidential') }}
            </h5>
            <h5 :class="`${isSubmitted ? 'mb-2 text-success' : 'mb-0'}`">
              {{ isSubmitted ? `${$t('message.user_number')}: ${authorUserNumber}` : $t('message.no_personal_data_recorded') }}
            </h5>
            <h5 v-if="isSubmitted" class="mb-0 text-warning">
              {{ `${$t('message.case_number')}: ${caseNumber}` }}
            </h5>
          </b-card>

          <form-wizard
            v-if="!isSubmitted"
            color="#498ceb"
            :title="null"
            :subtitle="null"
            layout="horizontal"
            :finish-button-text="$t('message.submit')"
            :back-button-text="$t('message.previous')"
            :next-button-text="$t('message.next')"
            class="wizard-vertical mb-3"
            @on-complete="formSubmitted"
            ref="myForm"
          >
            <!-- Type -->
            <tab-content :title="$t('label.complaintType')" :before-change="() => validateTab(typeTab)">
              <validation-observer ref="typeTab" tag="form">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('label.complaintType')"
                      :label-for="$t('label.complaintType')"
                    >
                      <validation-provider :name="$t('label.complaintType')" rules="required" v-slot="{ errors }">
                        <b-form-checkbox v-model="type" value="economicCrimesLaw" unchecked-value="" class="mt-1">
                          {{ $t('label.economicCrimesLaw') }}
                        </b-form-checkbox>

                        <b-form-checkbox v-model="type" value="karinLaw" unchecked-value="" class="my-1">
                          {{ $t('label.karinLaw') }}
                        </b-form-checkbox>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Terms and Conditions -->
            <tab-content :title="$t('label.terms_and_conditions')" :before-change="() => validateTab(termsTab)">
              <validation-observer ref="termsTab" tag="form">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('label.terms_and_conditions')"
                      :label-for="$t('label.terms_and_conditions')"
                    >
                      <div class="border mb-1">
                        <vue-perfect-scrollbar
                          :settings="{ maxScrollbarLength: 150 }"
                          class="scroll-area"
                        >
                          <div v-if="type === 'economicCrimesLaw'" class="py-1 pr-1">
                            <ol>
                              <li>{{ $t('message.ecl_complaint_terms_1_1') }} <u>{{ $t('message.ecl_complaint_terms_1_2') }}</u></li>
                              <li>{{ $t('message.ecl_complaint_terms_2_1') }} <u>{{ $t('message.ecl_complaint_terms_2_2') }}</u></li>
                              <li>{{ $t('message.ecl_complaint_terms_3') }}</li>
                              <li>{{ $t('message.ecl_complaint_terms_4') }}</li>
                            </ol>

                            <p class="pl-2">{{ $t('message.ecl_complaint_terms_5_1') }} <b><u>{{ $t('message.ecl_complaint_terms_5_2') }}</u></b></p>
                          </div>

                          <div v-else class="py-1 pr-1">
                            <ol>
                              <li>{{ $t('message.kl_complaint_terms_1') }}</li>
                              <li>{{ $t('message.ecl_complaint_terms_2_1') }} <u>{{ $t('message.ecl_complaint_terms_2_2') }}</u></li>
                              <li>{{ $t('message.kl_complaint_terms_2') }}</li>
                              <li>{{ $t('message.kl_complaint_terms_3') }}</li>
                              <li>{{ $t('message.ecl_complaint_terms_4') }}</li>
                            </ol>
  
                            <p class="pl-2">{{ $t('message.ecl_complaint_terms_5_1') }} <b><u>{{ $t('message.ecl_complaint_terms_5_2') }}</u></b></p>
                          </div>
                        </vue-perfect-scrollbar>
                      </div>

                      <validation-provider :name="$t('message.must_accept_terms')" rules="is:yes" v-slot="{ errors }">
                        <b-form-checkbox
                          id="checkbox-1"
                          v-model="acceptTerms"
                          name="checkbox-1"
                          value="yes"
                          unchecked-value="no"
                        >
                          {{ $t('message.accept_terms') }}
                        </b-form-checkbox>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Complaint -->
            <tab-content :title="$t('label.complaint')" :before-change="() => validateTab(complaintTab)">
              <validation-observer ref="complaintTab" tag="form">
                <!-- Economic Crimes Law -->
                <div v-if="type === 'economicCrimesLaw'">
                  <b-row>
                    <!-- Description -->
                    <b-col md="12">
                      <b-form-group :label="$t('label.complaint')" label-for="complaint">
                        <validation-provider :name="$t('label.complaint')" rules="required" v-slot="{ errors }">
                          <b-form-textarea
                            v-model="complaint.description"
                            rows="2"
                            :placeholder="$t('message.describe_what_when_where')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
  
                  <b-row>
                    <!-- Reported Person -->
                    <b-col md="6">
                      <b-form-group :label="$t('label.reported_person')" label-for="reported_person">
                        <validation-provider :name="$t('label.reported_person')" rules="required" v-slot="{ errors }">
                          <v-select
                            v-model="complaint.reportedPerson"
                            label="title"
                            taggable
                            push-tags
                            :options="employees"
                            :create-option="name => ({ title: name, value: name, addNewOption: true })"
                            :placeholder="$t('placeholder.reported_person')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
  
                    <!-- Reported Person Responsible -->
                    <b-col md="6">
                      <b-form-group :label="$t('label.reported_person_responsible')" label-for="reported_person_responsible">
                        <v-select
                          v-model="complaint.reportedPersonResponsible"
                          label="title"
                          taggable
                          push-tags
                          :options="employees"
                          :create-option="name => ({ title: name, value: name, addNewOption: true })"
                          :placeholder="$t('placeholder.reported_person_responsible')"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
  
                  <b-row>
                    <!-- Operational Areas Involved -->
                    <b-col md="6">
                      <b-form-group :label="$t('label.operationalAreasInvolved')" label-for="operationalAreasInvolved">
                        <v-select
                          v-model="complaint.areas"
                          label="title"
                          taggable
                          push-tags
                          :multiple="true"
                          :options="areas"
                          :create-option="name => ({ title: name, value: name, addNewOption: true })"
                          :placeholder="$t('placeholder.operationalAreasInvolved')"
                        />
                      </b-form-group>
                    </b-col>
  
                    <!-- Document -->
                    <b-col md="6">
                      <b-form-group :label="$t('label.document')" label-for="document">
                        <b-button
                          class="mr-1"
                          type="button"
                          variant="info"
                          @click="activateFileSelector"
                        >
                          {{ $t('message.upload') }}
                        </b-button>
                        <input
                          type="file"
                          ref="fileSelector"
                          style="display: none"
                          :accept="inputFileTypes"
                          multiple
                          @change="changeFileSelected"
                        />
                        <small v-if="documentFile && documentFile.length">
                          {{ documentFile.length === 1 ? documentFile[0].name : `${documentFile.length} ${$t('documents')}` }}
                        </small>
                      </b-form-group>
                    </b-col>
                  </b-row>
  
                  <b-row>
                    <!-- Themes -->
                    <b-col md="6">
                      <b-form-group :label="$t('label.themes')" label-for="themes">
                        <validation-provider :name="$t('label.themes')" rules="required" v-slot="{ errors }">
                          <v-select
                            v-model="complaint.themes"
                            label="title"
                            taggable
                            push-tags
                            :multiple="true"
                            :options="complaintThemes"
                            :create-option="name => ({ title: name, value: 'newOption' })"
                            :placeholder="$t('placeholder.themes')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
  
                    <!-- Company Relationship -->
                    <b-col md="6">
                      <b-form-group :label="$t('label.companyRelationship')" label-for="companyRelationship">
                        <v-select
                          v-model="complaint.companyRelationship"
                          label="title"
                          taggable
                          push-tags
                          :options="complaintRelationshipOptions"
                          :create-option="name => ({ title: name, value: 'newOption' })"
                          :placeholder="$t('placeholder.companyRelationship')"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>

                <!-- Karin Law -->
                <div v-else>
                  <b-row>
                    <!-- Themes -->
                    <b-col md="6">
                      <b-form-group :label="$t('label.themes')" label-for="themes">
                        <validation-provider :name="$t('label.themes')" rules="required" v-slot="{ errors }">
                          <v-select
                            v-model="complaint.themes"
                            label="title"
                            :multiple="true"
                            :options="karinLawThemes"
                            :placeholder="$t('placeholder.themes')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <!-- Author -->
                    <b-col md="4" class="border-right">
                      <b-form-group :label="$t('label.personMakingComplaintIdentification')" label-for="personMakingComplaintIdentification" label-class="font-weight-bold">
                        <!-- Name -->
                        <label>{{ $t('label.Name') }}</label>
                        <div>
                          <validation-provider :name="`${$t('label.Name')} 1`" rules="required" v-slot="{ errors }">
                            <b-form-input
                              v-model="complaint.author.name"
                              trim
                              :placeholder="$t('placeholder.name')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>

                        <!-- RUT -->
                        <label>{{ $t('message.tableHeader.rut') }}</label>
                        <div>
                          <validation-provider :name="`${$t('message.tableHeader.rut')} 1`" rules="required" v-slot="{ errors }">
                            <b-form-input
                              v-model="complaint.author.rut"
                              trim
                              :placeholder="$t('placeholder.rut')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>

                        <!-- Email -->
                        <label>{{ $t('label.Email') }}</label>
                        <div>
                          <validation-provider :name="`${$t('label.Email')} 1`" rules="required|email" v-slot="{ errors }">
                            <b-form-input
                              v-model="complaint.author.email"
                              trim
                              :placeholder="$t('placeholder.email')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>

                        <!-- Company -->
                        <label>{{ $t('label.company') }}</label>
                        <div>
                          <validation-provider :name="`${$t('label.company')} 1`" rules="required" v-slot="{ errors }">
                            <b-form-input
                              v-model="complaint.author.company"
                              trim
                              :placeholder="$t('placeholder.company')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </b-form-group>
                    </b-col>

                    <!-- Affected Person -->
                    <b-col md="4" class="border-right">
                      <b-form-group :label="$t('label.affectedPersonIdentification')" label-for="affectedPersonIdentification" label-class="font-weight-bold">
                        <!-- Author === Affected Person Checkbox -->
                        <b-form-checkbox v-model="isAuthorSameAsAffectedPerson" class="my-1">
                          <small>{{ $t('label.authorSameAsAffectedPerson') }}</small>
                        </b-form-checkbox>
                        
                        <!-- Name -->
                        <label>{{ $t('label.Name') }}</label>
                        <div>
                          <validation-provider :name="`${$t('label.Name')} 2`" rules="required" v-slot="{ errors }">
                            <b-form-input
                              v-model="complaint.affectedPerson.name"
                              trim
                              :disabled="isAuthorSameAsAffectedPerson"
                              :placeholder="$t('placeholder.name')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>

                        <!-- RUT -->
                        <label>{{ $t('message.tableHeader.rut') }} {{ $t('message.(optional)') }}</label>
                        <b-form-input
                          v-model="complaint.affectedPerson.rut"
                          trim
                          :disabled="isAuthorSameAsAffectedPerson"
                          :placeholder="$t('placeholder.rut')"
                        />

                        <!-- Email -->
                        <label>{{ $t('label.Email') }}</label>
                        <div>
                          <validation-provider :name="`${$t('label.Email')} 2`" rules="required|email" v-slot="{ errors }">
                            <b-form-input
                              v-model="complaint.affectedPerson.email"
                              trim
                              :disabled="isAuthorSameAsAffectedPerson"
                              :placeholder="$t('placeholder.email')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>

                        <!-- Company -->
                        <label>{{ $t('label.company') }}</label>
                        <div>
                          <validation-provider :name="`${$t('label.company')} 2`" rules="required" v-slot="{ errors }">
                            <b-form-input
                              v-model="complaint.affectedPerson.company"
                              trim
                              :disabled="isAuthorSameAsAffectedPerson"
                              :placeholder="$t('placeholder.company')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </b-form-group>
                    </b-col>

                    <!-- Reported Person -->
                    <b-col md="4">
                      <b-form-group :label="$t('label.reportedPersonIdentification')" label-for="reportedPersonIdentification" label-class="font-weight-bold">
                        <!-- Name -->
                        <label>{{ $t('label.Name') }}</label>
                        <div>
                          <validation-provider :name="`${$t('label.Name')} 3`" rules="required" v-slot="{ errors }">
                            <b-form-input
                              v-model="complaint.reportedPersonData.name"
                              trim
                              :placeholder="$t('placeholder.name')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>

                        <!-- RUT -->
                        <label>{{ $t('message.tableHeader.rut') }} {{ $t('message.(optional)') }}</label>
                        <b-form-input
                          v-model="complaint.reportedPersonData.rut"
                          trim
                          :placeholder="$t('placeholder.rut')"
                        />

                        <!-- Email -->
                        <label>{{ $t('label.Email') }} {{ $t('message.(optional)') }}</label>
                        <div>
                          <validation-provider :name="`${$t('label.Email')} 3`" rules="email" v-slot="{ errors }">
                            <b-form-input
                              v-model="complaint.reportedPersonData.email"
                              trim
                              :placeholder="$t('placeholder.email')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>

                        <!-- Position -->
                        <label>{{ $t('label.position') }}</label>
                        <div>
                          <validation-provider :name="`${$t('label.position')} 3`" rules="required" v-slot="{ errors }">
                            <b-form-input
                              v-model="complaint.reportedPersonData.position"
                              trim
                              :placeholder="$t('placeholder.position')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>

                        <!-- Company -->
                        <label>{{ $t('label.company') }}</label>
                        <div>
                          <validation-provider :name="`${$t('label.company')} 3`" rules="required" v-slot="{ errors }">
                            <b-form-input
                              v-model="complaint.reportedPersonData.company"
                              trim
                              :placeholder="$t('placeholder.company')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row class="mt-1">
                    <!-- Company Relationship -->
                    <b-col md="6">
                      <b-form-group :label="$t('label.organizationalLinkBetweenTheParties')" label-for="organizationalLinkBetweenTheParties">
                        <validation-provider :name="$t('label.link')" rules="required" v-slot="{ errors }">
                          <v-select
                            v-model="complaint.companyRelationship"
                            label="title"
                            :options="complaintRelationshipOptions"
                            :placeholder="$t('placeholder.link')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <!-- Description -->
                    <b-col md="12">
                      <b-form-group :label="$t('label.factsThatAreReported')" label-for="factsThatAreReported">
                        <validation-provider :name="$t('label.factsThatAreReported')" rules="required" v-slot="{ errors }">
                          <b-form-textarea
                            v-model="complaint.description"
                            rows="2"
                            :placeholder="$t('message.describe_what_when_where')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <!-- Document -->
                    <b-col md="6">
                      <b-form-group :label="$t('label.additionalBackgroundUpload')" label-for="additionalBackgroundUpload">
                        <b-button
                          class="mr-1"
                          type="button"
                          variant="info"
                          @click="activateFileSelector"
                        >
                          {{ $t('message.upload') }}
                        </b-button>
                        <input
                          type="file"
                          ref="fileSelector"
                          style="display: none"
                          :accept="inputFileTypes"
                          multiple
                          @change="changeFileSelected"
                        />
                        <small v-if="documentFile && documentFile.length">
                          {{ documentFile.length === 1 ? documentFile[0].name : `${documentFile.length} ${$t('documents')}` }}
                        </small>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </validation-observer>
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BListGroup, BListGroupItem, BFormCheckbox, BFormTextarea, BFormInput } from 'bootstrap-vue'
import AddCoLogo from '@core/layouts/components/Logo.vue'
import { ref, onMounted, watch } from "@vue/composition-api"
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import axios from "@axios"
import useNotifications from "@/composables/useNotifications"
import { addComplaint, updateComplaint } from "@core/queries/complaints"
import { getClient } from '@/@core/queries/clients'
import workersQuery from '@/@core/queries/workers'
import { addWorker } from '@/@core/queries/workers'
import locationsQuery from '@/@core/queries/locations'
import { addLocation } from '@/@core/queries/locations'
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'
import { FormWizard, TabContent } from "vue-form-wizard"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { required, is } from "@validations"
import vSelect from 'vue-select'
import { complaintThemes, complaintRelationshipOptions, karinLawThemes, inputFileTypes } from '@/constants'
import awsConnection from '@/views/habit/aws'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'

export default {
  components: {
    AddCoLogo,
    BLink,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormTextarea,
    BFormInput,
    Loading,
    FormWizard,
    TabContent,
    VuePerfectScrollbar,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  setup() {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { singleUpload } = awsConnection()
    const { route } = useRouter()
    const { sendEmailCase } = useCommonTodo()
    const { clientPublicId } = route.value.params
    const clientData = ref({})
    const clientNotFound = ref(false)
    const employees = ref([])
    const areas = ref([])
    const isLoading = ref(true)
    const isSubmitting = ref(false)
    const isSubmitted = ref(false)
    const acceptTerms = ref('no')
    const typeTab = ref(null)
    const termsTab = ref(null)
    const complaintTab = ref(null)
    const complaint = ref({ author: {}, affectedPerson: {}, reportedPersonData: {} })
    const fileSelector = ref(null)
    const documentFile = ref([])
    const type = ref("")
    const isAuthorSameAsAffectedPerson = ref(false)

    watch(type, () => {
      // Reset variables and form validations
      acceptTerms.value = 'no'
      complaint.value = { author: {}, affectedPerson: {}, reportedPersonData: {} }
      termsTab.value.reset()
      complaintTab.value.reset()
    })

    watch(isAuthorSameAsAffectedPerson, (val) => {
      if (val) complaint.value.affectedPerson = complaint.value.author
      else complaint.value.affectedPerson = { ...complaint.value.affectedPerson }
    })

    const getUniqueId = () => {
      return Math.floor(Date.now() * Math.random()).toString()
    }

    // Generate random unique numbers that will be associated with the complaint
    const authorUserNumber = getUniqueId()
    const affectedUserNumber = getUniqueId()
    const reportedUserNumber = getUniqueId()
    const caseNumber = getUniqueId()

    const activateFileSelector = () => {
      fileSelector.value.click()
    }

    const changeFileSelected = (e) => {
      documentFile.value = Array.from(e.target.files)
    }

    const formSubmitted = async () => {
      isSubmitting.value = true

      try {
        if (complaint.value.reportedPerson?.addNewOption) {
          complaint.value.reportedPerson.value = await createNewWorker(complaint.value.reportedPerson.title)
        }
        if (complaint.value.reportedPersonResponsible?.addNewOption) {
          complaint.value.reportedPersonResponsible.value = await createNewWorker(complaint.value.reportedPersonResponsible.title)
        }
        if (complaint.value.areas?.length) {
          for (const area of complaint.value.areas) {
            if (area?.addNewOption) area.value = await createNewArea(area.title)
          }
        }
      } catch (error) {
        showErrorMessage(i18n.t(`message.${error === 'workerError' ? 'worker_error' : 'location_add_error'}`))
        isSubmitting.value = false
        return
      }

      const payload = {
        client_id: { link: clientData.value._id },
        description: complaint.value.description,
        reportedPerson: complaint.value.reportedPerson ? { link: complaint.value.reportedPerson.value } : null,
        reportedPersonResponsible: complaint.value.reportedPersonResponsible ? { link: complaint.value.reportedPersonResponsible.value } : null,
        areas: complaint.value.areas ? { link: complaint.value.areas.map(e => e.value) } : null,
        themes: complaint.value.themes?.map(e => ({ value: e.value === 'newOption' ? e.title : e.value, isNewOption: e.value === 'newOption' || null })),
        createdAt: new Date(),
        authorUserNumber,
        affectedUserNumber,
        reportedUserNumber,
        caseNumber,
        dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
        companyRelationship: !complaint.value.companyRelationship?.value ? null : {
          value: complaint.value.companyRelationship.value === 'newOption' ? complaint.value.companyRelationship.title : complaint.value.companyRelationship.value,
          isNewOption: complaint.value.companyRelationship.value === 'newOption' || null,
        },
        stage: "registration",
        author: complaint.value.author.name ? complaint.value.author : null,
        affectedPerson: complaint.value.affectedPerson.name ? complaint.value.affectedPerson : null,
        reportedPersonData: complaint.value.reportedPersonData.name ? complaint.value.reportedPersonData : null,
        isKarinLaw: type.value === "karinLaw" || null,
        rootCause: 100,
        rootCauseCorrectiveMeasures: 100,
      }

      axios.post("/graphql", {
        query: addComplaint,
        variables: { data: payload },
      })
        .then(async ({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          showSuccessMessage(i18n.t('message.complaint_success'))
          
          const complaintId = data.data.insertOneComplaint._id
          const destinationFolder = `${clientData.value._id}/complaints`
          
          const uploadDocumentsPromises = documentFile.value?.map(file => {
            if (!file?.name) return null
            return new Promise((resolve, reject) => {
              singleUpload(file, destinationFolder)
                .then((key) => resolve(key))
                .catch((err) => {
                  console.log(err)
                  resolve(null)
                })
            })
          }) || []

          const documentKeys = await Promise.all(uploadDocumentsPromises)

          updateComplaintWithKey(complaintId, documentKeys.filter(e => !!e))

          // Send email to the client complaint recipient with the details of the complaint created
          complaint.value._id = complaintId
          complaint.value.stage = payload.stage
          complaint.value.type = type.value
          sendEmailCase(complaint.value, 'add', null, 'complaint', clientData.value.complaintRecipients)

          isSubmitted.value = true
        })
        .catch((error) => {
          console.log(error)
          showErrorMessage(i18n.t('message.complaint_submit_error'))
        })
        .finally(() => {
          isSubmitting.value = false
        })
    }

    const validateTab = (tabRef) => new Promise((resolve, reject) => {
      tabRef.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const updateComplaintWithKey = (complaintId, keys) => {
      axios
        .post('/graphql', {
          query: updateComplaint,
          variables: {
            query: { _id: complaintId },
            data: { documentKey: keys },
          },
        })
        .then(() => {})
        .catch(error => console.log(error))
    }

    const getEmployees = (clientId) => {
      const conditions = {
        client_id: { _id: clientId },
        deleted_ne: true
      }

      axios
        .post("/graphql", {
          query: workersQuery,
          variables: { query: conditions, limit: 20000, sortBy: "NAME_ASC" },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          employees.value = data.data?.workers?.map(({ name, _id, onlyDropdownOption }) => (
            { title: onlyDropdownOption ? i18n.t(`label.${name}`) : name, value: _id }
          )) || []
        })
        .catch((error) => {
          console.log(error)
          showErrorMessage(i18n.t('message.err_worker_list'));
        })
    }

    const getAreas = (clientId) => {
      const conditions = {
        client_id: { _id: clientId },
        deleted_ne: true
      }

      axios
        .post("/graphql", {
          query: locationsQuery,
          variables: { query: conditions, limit: 20000, sortBy: "LOCATION_ASC" },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          areas.value = data.data?.locations?.map(({ location, _id, onlyDropdownOption }) => (
            { title: onlyDropdownOption ? i18n.t(`label.${location}`) : location, value: _id }
          )) || []
        })
        .catch((error) => {
          console.log(error)
          showErrorMessage(i18n.t('message.location_list_fetch_error'));
        })
    }

    const createNewWorker = (name) => {
      const payload = {
        client_id: { link: clientData.value._id },
        name,
        privileges: 'employee'
      }

      return new Promise((resolve, reject) => {
        axios
          .post("/graphql", {
            query: addWorker,
            variables: { data: payload },
          })
          .then(({data}) => {
            if (data.errors) throw new Error(data.errors[0].message)
            resolve(data.data.insertOneWorker._id)
          })
          .catch((error) => {
            console.log(error)
            reject("workerError")
          })
      })
    }

    const createNewArea = (name) => {
      const payload = {
        client_id: { link: clientData.value._id },
        location: name,
        concept: 'VARIAS'
      }

      return new Promise((resolve, reject) => {
        axios
          .post("/graphql", {
            query: addLocation,
            variables: { data: payload },
          })
          .then(({data}) => {
            if (data.errors) throw new Error(data.errors[0].message)
            resolve(data.data.insertOneLocation._id)
          })
          .catch((error) => {
            console.log(error)
            reject("locationError")
          })
      })
    }

    onMounted(async () => {
      try {
        // If route doesn't have the client public id, throw error
        if (!clientPublicId) throw new Error("Client public id not present in route params")

        // If user is not logged in (either as a real or anonymous user), then log in as an anonymous user
        if (!useJwt.realm.currentUser) await useJwt.loginAsAnonymousUser()

        // Get client data based on client public id from URL param
        const { data } = await axios.post("/graphql", {
          query: getClient,
          variables: { query: { publicId: clientPublicId } },
        })

        if (data.errors) throw new Error(data.errors[0].message)
        if (!data.data?.client) throw new Error("Client not found")
        clientData.value = data.data.client

        // Get data for dropdowns
        getEmployees(clientData.value._id)
        getAreas(clientData.value._id)

        // Define locale based on client default language
        i18n.locale = clientData.value.default_language
        localize(clientData.value.default_language)

      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.err_client_fetch'))
        clientNotFound.value = true

      } finally {
        isLoading.value = false
      }
    })

    return {
      clientData,
      clientNotFound,
      isLoading,
      isSubmitting,
      isSubmitted,
      acceptTerms,
      required,
      is,
      validateTab,
      typeTab,
      termsTab,
      complaintTab,
      formSubmitted,
      complaint,
      employees,
      areas,
      fileSelector,
      activateFileSelector,
      changeFileSelected,
      documentFile,
      complaintThemes,
      complaintRelationshipOptions,
      authorUserNumber,
      caseNumber,
      type,
      karinLawThemes,
      isAuthorSameAsAffectedPerson,
      inputFileTypes,
    };
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
@import "@core/scss/vue/libs/vue-wizard.scss";
</style>

<style scoped>
.scroll-area {
  position: relative;
  max-height: 150px;
}
.border-right {
  border-right: 1px solid #ccc !important;
}
</style>